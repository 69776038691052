.main-container {
    padding: 8px;
    min-height: 600px;
}

.my-image {
    width: 100%;
}

.text {
    display: flex;
    flex-direction: column;
}

@media (min-width: 1000px) {
    .main-container {
        padding: 8px 200px;
    }
}