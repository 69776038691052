.footer {
    display: flex;
    background-color: var(--dark-grey);
    color: var(--white);
    min-height: 200px;
    padding: 8px;
    text-align: center;
    justify-content: space-between;
    align-items: center;
}

.signature {
    color: var(--white);
}

.social-icons-container {
    display: flex;
    flex-direction: column;
    height: 150px;
    justify-content: space-evenly;
    align-items: flex-end;
}

.social-icon {
    display: flex;
    gap: 8px;
    color: var(--white);
}

@media (min-width: 700px) {
    .footer {
        padding: 8px 100px;
    }
}

@media (min-width: 900px) {
    .footer {
        padding: 8px 300px;
    }
}