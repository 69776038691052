.buttonSection {
    padding: 8px;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.buttonContainer {
    position: relative;
    text-align: center;
}

.buttonContainer:hover {
    opacity: 0.6;
    cursor: pointer;
}

.imageButton {
    width: 100%;
    height: auto;
    box-sizing: border-box;
}

.dotworkImage {
    opacity: 0.7;
}

.buttonText {
    position: absolute;
    text-align: center;
    margin: 0 auto;
    top: 40%;
    left: 0;
    right: 0;
    z-index: 1;
    color: var(--white);
    font-size: 1.8em;
}

.dotworkButton {
    color: var(--black);
    top: 45%;
    font-weight: bold;
}

@media (min-width: 800px) {
    .buttonText {
        font-size: 3em;
    }
}

@media (min-width: 1200px) {
    .buttonSection {
        padding: 8px 300px;
    }

    .buttonText {
        font-size: 4em;
    }
}