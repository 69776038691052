.navbar {
    background-color: var(--white);
    padding: 0px 8px;
    position: fixed;
    width: 100%;
    top: 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 1.3em;
    z-index: 100;
}

.navElement {
    padding: 4px;
    cursor: pointer;
}

.navElement:hover {
    background-color: var(--middle-grey);
    color: var(--white);
    border: var(--middle-grey) solid 4px;
    padding: 4px;
    opacity: 0.8;
}

.placeholder {
    height: 42px;
}

.selectedNavElement {
    color: var(--white);
    background-color: var(--dark-grey);
    border: var(--dark-grey) solid 4px;
    padding: 4px;
}

@media (min-width: 1200px) {
    .navbar {
        padding: 0px 200px;
    }
}