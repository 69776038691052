.imageContainer {
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.image {
    width: 100%;
}

@media (min-width: 1000px) {
    .imageContainer {
        padding: 8px 200px;
    }
}